import { __awaiter } from "tslib";
// TODO: This is a temporary entrypoint used for desktop only and will be removed. It is needed so that bp dealCards used on mobile web tests (MobileWeb Unified Feed Test V2.1) don't override different version bp dealCard used on desktop.
import readyState from 'ready-state';
import LazyLoad from '@slickdeals/blueprint-twig/javascript/core/lazyLoad';
import '@slickdeals/blueprint-twig/javascript/patterns/dealContentListModule';
import '@slickdeals/blueprint-twig/javascript/patterns/storeLink';
import '@slickdeals/blueprint-twig/javascript/patterns/storeCard';
import '@slickdeals/blueprint-twig/javascript/components/cardCarousel';
import '../patterns/latestArticles';
import './standalonePageEntrypointBanner';
import '../patterns/trendingStores';
import '../patterns/sidebarDeals';
import '../patterns/sidebarCoupons';
import '../recipes/holidaySeoBlock';
import '../recipes/cashbackOffersWidget';
import '../patterns/cashbackTagActivationModal';
import '../patterns/inlineResponse';
import { ClassNames as JustForYouCarouselWrapperClassNames } from '../patterns/justForYouCarouselWrapper';
import { initializeDealCardSubscriber } from '../subscribers/dealCardSubscriber';
import AdRefreshEvents from '../utilities/adRefreshEvents';
import { initPage } from '../utilities/initPage';
import { trackItemListImpression, trackInclick } from '@slickdeals/web-instrumentation';
import Logger from '@slickdeals/durango/dist/javascript/utilities/logger';
/** The name of the module */
export const name = 'Frontpage';
initPage();
initializeDealCardSubscriber();
const regionType = 'frontpage';
const data = {
    regionName: name,
    regionType,
    containerId: '',
    containerName: '',
};
const handleInclick = (card, index, containerName) => (e) => {
    var _a, _b, _c, _d, _e, _f, _g;
    const newUrl = e.target.href;
    const urlParams = new URLSearchParams(window.location.search);
    const pageNumber = parseInt(urlParams.get('page') || '0', 10);
    const inclickData = Object.assign(Object.assign({}, data), { url: newUrl, dealIndex: index, page: pageNumber, containerName });
    const match = newUrl.match(/\/f\/(\d+)-/);
    const threadId = match ? match[1] : '';
    const deal = {
        uniqueId: card.getAttribute('data-uniqueid') || card.getAttribute('uniqueid') || threadId,
        dealTitle: ((_a = card.querySelector('.dealCard__title')) === null || _a === void 0 ? void 0 : _a.textContent.trim()) || ((_b = card.querySelector('.dealCardList__title')) === null || _b === void 0 ? void 0 : _b.textContent.trim()) || ((_c = card.querySelector('.bp-p-sidebarDeals_title')) === null || _c === void 0 ? void 0 : _c.textContent.trim()) || '',
        isExpired: card.classList.contains('dealCard--expired') || card.classList.contains('dealCardList--expired'),
        listPriceText: (_e = (_d = card.querySelector('.dealCard__originalPrice')) === null || _d === void 0 ? void 0 : _d.textContent.trim()) !== null && _e !== void 0 ? _e : '',
        finalPriceText: ((_f = card.querySelector('.dealCard__price')) === null || _f === void 0 ? void 0 : _f.textContent.trim()) || ((_g = card.querySelector('.dealCardList__salePrice')) === null || _g === void 0 ? void 0 : _g.textContent.trim()) || '',
    };
    trackInclick(inclickData, deal);
};
const addLinkListeners = (selector, containerName) => {
    const cards = document.querySelectorAll(selector);
    cards.forEach((card, index) => {
        const links = card.querySelectorAll('a');
        links.forEach((link) => {
            link.addEventListener('click', handleInclick(card, index, containerName));
        });
    });
};
const trackImpression = () => {
    const dealCards = document.querySelectorAll('.frontpageGrid .dealCard, .frontpageGrid .dealCardList');
    const deals = Array.from(dealCards).map((card) => {
        var _a, _b, _c, _d, _e, _f;
        return ({
            uniqueId: card.getAttribute('data-uniqueid') || card.getAttribute('uniqueid'),
            dealTitle: ((_a = card.querySelector('.dealCard__title')) === null || _a === void 0 ? void 0 : _a.textContent.trim()) || ((_b = card.querySelector('.dealCardList__title')) === null || _b === void 0 ? void 0 : _b.textContent.trim()) || '',
            isExpired: card.classList.contains('dealCard--expired') || card.classList.contains('dealCardList--expired'),
            listPriceText: (_d = (_c = card.querySelector('.dealCard__originalPrice')) === null || _c === void 0 ? void 0 : _c.textContent.trim()) !== null && _d !== void 0 ? _d : '',
            finalPriceText: ((_e = card.querySelector('.dealCard__price')) === null || _e === void 0 ? void 0 : _e.textContent.trim()) || ((_f = card.querySelector('.dealCardList__salePrice')) === null || _f === void 0 ? void 0 : _f.textContent.trim()) || '',
        });
    });
    trackItemListImpression(data, deals);
    addLinkListeners('.frontpageSlickdealsGrid .dealCard, .frontpageSlickdealsGrid .dealCardList', 'Frontpage');
    addLinkListeners('[data-module-name="Popular Deals"] .bp-p-sidebarDeals_deal', 'Popular Deals');
    addLinkListeners('[data-module-name="Trending Deals"] .bp-p-sidebarDeals_deal', 'Trending Deals');
    addLinkListeners('.dealsYouMayHaveMissed .dealCard, .dealsYouMayHaveMissed .dealCardList', 'Deals You May Have Missed');
};
readyState.domready.then(() => __awaiter(void 0, void 0, void 0, function* () {
    const lazyLoad = new LazyLoad();
    (new AdRefreshEvents()).initializeAdRefreshOnUpscroll();
    const frontpageSlickdealsContainer = document.querySelector('[data-module-name*="Frontpage Slickdeals"]');
    const dealYouMayHaveMissedContainer = document.querySelector('[data-module-name="Deals You May Have Missed"]');
    const cashbackOffersWidgetContainer = document.querySelector('[data-module-name*="Best Cashback Offers"]');
    const justForYouCarouselContainer = document.querySelector(`.${JustForYouCarouselWrapperClassNames.Javascript}`);
    const lazyLoadContainers = [frontpageSlickdealsContainer, dealYouMayHaveMissedContainer, cashbackOffersWidgetContainer, justForYouCarouselContainer];
    lazyLoadContainers.forEach(container => {
        if (container !== null) {
            lazyLoad.setObserver(container);
        }
    });
    const waitForLoadingCardsToDisappear = () => new Promise((resolve) => {
        const observer = new MutationObserver(() => {
            const missedDeals = document.querySelector('.dealsYouMayHaveMissed');
            const loadingCards = missedDeals === null || missedDeals === void 0 ? void 0 : missedDeals.querySelectorAll('.dealCard--loading');
            if (missedDeals && loadingCards.length === 0) {
                observer.disconnect();
                resolve();
            }
        });
        observer.observe(document.body, { childList: true, subtree: true });
        // Initial check in case there are no loading cards at the start
        const missedDeals = document.querySelector('.dealsYouMayHaveMissed');
        const initialLoadingCards = missedDeals === null || missedDeals === void 0 ? void 0 : missedDeals.querySelectorAll('.dealCard--loading');
        if (missedDeals && initialLoadingCards.length === 0) {
            observer.disconnect();
            resolve();
        }
    });
    waitForLoadingCardsToDisappear().then(() => {
        try {
            trackImpression();
        }
        catch (_a) {
            Logger.error('Unable to track frontpage impression');
        }
    });
}));
